.input-color {
  display: inline-flex;
  flex-flow: column nowrap;
  /* 57px */
  height: 3.5625rem;
}

.input-color--disabled {
  opacity: 0.6;
}

.input-color__inner {
  position: relative;
}

.input-color__button {
  align-items: center;
  background: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  font: var(--font-lg-medium);
  height: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  text-align: initial;
}

.input-color__button::before {
  background: currentColor;
  border-radius: 99999px;
  content: '';
  display: block;
  height: var(--spacing-10);
  width: var(--spacing-10);
}

.input-color__button span {
  color: var(--color-grey-900);
  margin: 0 0 0 var(--spacing-2);
}

.input-color__input {
  cursor: pointer;
  height: var(--spacing-10);
  opacity: 0;
  position: absolute;
  top: 0;
  width: var(--spacing-10);
}

.input-color__label {
  align-items: center;
  color: var(--color-grey-900);
  cursor: pointer;
  display: flex;
  font: var(--font-sm-medium);
  height: var(--spacing-6);
  justify-content: inherit;
}

.input-color--disabled .input-color__label {
  color: var(--color-grey-700);
}
