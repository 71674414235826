:root {
  --height-cell: 20px; 
}
.time-grid {
  grid-template-rows: 1fr;
}

.time-grid--edited {
  cursor: move;
}

.time-grid--edited-move {
  cursor: move;
}

.time-grid--edited-horizontal {
  cursor: ew-resize;
}

.time-grid--edited-vertical {
  cursor: ns-resize;
}

.time-grid--edited *:not(.time-grid__row) {
  pointer-events: none;
}

.time-grid__row {
  position: relative;
  grid-auto-rows: var(--height-cell);
  background-image: linear-gradient(0deg,
    transparent 23.75%, 
  var(--color-border-grid) 23.75%,
  var(--color-border-grid) 25%, 
  transparent 25%, 
  transparent 48.75%,
  var(--color-border-grid) 48.75%, 
  var(--color-border-grid) 50%, 
  transparent 50%,
  transparent 73.75%, 
  var(--color-border-grid) 73.75%, 
  var(--color-border-grid) 75%, 
  transparent 75%, 
  transparent 98.75%, 
  var(--color-border-grid) 98.75%, 
  var(--color-border-grid) 100%);
  background-size: 80px 80px;
  background-repeat: repeat;
}

.time-grid__label-row {
  grid-auto-rows: var(--height-cell);
  position: sticky;
  left: 0;
  z-index: var(--sticky-z-index);
}

.time-grid__label-cell {
  height: 100%;
  line-height: var(--height-cell);
}

.time-grid__label-row::after {
  display:    block;
  content:    '';
  position:   absolute;
  width:      var(--size-material-shadow);
  height:     calc(100% + 2px);
  top:        -1px;
  right:      calc(0px - var(--size-material-shadow));
  background: linear-gradient(to right, 
    var(--color-material-shadow-inner), 
    transparent);
}

.time-grid__label-cell--border-solid {
  border-bottom: 1px solid var(--color-border-grid);
}
