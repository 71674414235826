.prompt {
  border-radius: var(--spacing-2);
  display: flex;
  flex-flow: column nowrap;
  max-height: 100%;
  overflow: hidden;
  width: 512px;
  max-width: var(--dialog-max-width-sm, 480px);
  background-color: var(--color-bw-50);
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
}

.prompt-content-wrapper {
  padding: 0 var(--spacing-2);
}

.prompt-content--destructive {
  background-color: var(--color-error-50);
  color: var(--color-error-700);
  padding: var(--spacing-4) var(--spacing-3);
}

.prompt-content {
  font: var(--font-sm-regular);
}

.prompt-footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  padding: var(--spacing-6) var(--spacing-8) var(--spacing-8) var(--spacing-8);
}

.prompt-footer__main-action-buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
}

.prompt-footer *:not(:last-child) {
  margin-right: var(--spacing-3);
}
