.main-view {
  grid-area: "main";
  display: grid;
  grid-template:
    "navigation header" max-content
    "navigation view" 1fr / max-content 1fr;
  height: 100vh;
}

@media screen and (max-width: 767px) {
  .main-view {
    grid-template:
      "header" max-content
      "view" 1fr
      "navigation" max-content;
  }
}

.main-view__header {
  grid-area: header;
}

.main-view__navigation {
  grid-area: navigation;
}

.main-view__view {
  background: var(--color-grey-50);
  display: flex;
  grid-area: view;
  overflow: hidden;
}

.main-view__main {
  flex: 3;
  overflow: hidden;
  transition: flex var(--transition-duration-default);
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 767px) {
  .main-view__main--chat-open {
    flex: 0;
  }
}
