.chat-button {
  background: 0;
  border: 0;
  color: var(--color-grey-700);
  cursor: pointer;
  height: 100%;
  outline: 0;
  padding: var(--spacing-2) var(--spacing-4);
  position: relative;
}

.chat-button:hover {
  background-color: var(--color-primary-100);
}

.chat-button::before {
  border-radius: 50%;
  content: ' ';
  height: 10px;
  left: 15px;
  position: absolute;
  top: 10px;
  transform-origin: 0 0;
  transform: scale(0);
  transition: transform 0.3s ease-in 0.2s, opacity 0.3s ease-in 0.2s;
  width: 10px;
}

.chat-button--with-notification::before {
  opacity: 1;
  transform: scale(1) translate(13px, 17px);
}
