.breaks {
  align-items: center;
  display: grid;
  gap: var(--spacing-3) var(--spacing-6);
}

.breaks__unpaid-break-input {
  grid-column: 1 / -1;
}

.breaks__add-button {
  justify-self: flex-start;
}
