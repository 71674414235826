.support-banner {
  background: #42A5F5;
  bottom: 0;
  color: var(--color-bw-50);
  display: flex;
  left: 0;
  line-height: 1.8;
  padding: 10px;
  position: fixed;
  transition: transform 0.3s ease;
  width: 100%;
  z-index: 9999;
}

.support-banner__text {
  flex: 1;
  font-size: 14px;
  text-align: center;
}

.support-banner__text a {
  color: inherit;
  font-weight: bold;
}

.support-banner__text a:focus,
.support-banner__text a:hover {
  text-decoration: none;
}

.support-banner__button {
  background: 0;
  border: 0;
  color: inherit;
  cursor: pointer;
  outline: 0;
  padding: 0;
}

.support-banner__icon {
  font-size: 21px;
  font-weight: bold;
  line-height: 1.2;
}

.transition-slide-enter.support-banner,
.transition-slide-leave-to.support-banner {
  transform: translateY(100%);
}

.transition-slide-enter-to.support-banner,
.transition-slide-leave.support-banner {
  transform: translateY(0);
}
