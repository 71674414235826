.input-select {
  display: inline-flex;
  flex-flow: column nowrap;
  transition: background var(--transition-fast), border var(--transition-fast);
  position: relative;
  overflow: hidden;
}

.input-select--disabled {
  /* noop */
}

.input-select-wrapper {
  position: relative;
}

.input-select__label {
  cursor: pointer;
  font: var(--font-sm-medium);
  color: var(--color-grey-700);
  margin-bottom: var(--spacing-1);
}

.input-select--disabled .input-select__label {
  cursor: default;
}

.input-select__hint-text {
  font: var(--font-sm-regular);
  margin-top: var(--spacing-2);
  color: var(--color-grey-500);
}

.input-select__suffix-icon {
  margin-left: var(--spacing-2);
  flex-shrink: 0;
}

.input-select__hint-text.input-select__hint-text--error,
.input-select__suffix-icon--error {
  color: var(--color-error-500);
}

.input-select__hint-text.input-select__hint-text-success,
.input-select__suffix-icon--success {
  color: var(--color-success-500);
}

.input-select__select-button {
  transition: border var(--transition-fast);
  position: relative;
  padding: var(--spacing-2) var(--spacing-3);
  border-radius: var(--spacing-1);
  border: 1px solid var(--color-grey-300);
  font: var(--font-sm-medium);
  color: var(--color-grey-700);
  align-items: center;
  background: 0;
  display: flex;
  outline: 0;
  width: 100%;
  cursor: pointer;

  /* needed to match the height of the inputs */
  line-height: 22px;
}

.input-select__select-button:disabled {
  background-color: var(--color-grey-100);
  color: var(--color-grey-500);
  cursor: default;
}

.input-select__validation-input {
  position: absolute;
  z-index: -99999;
}

.input-select__validation-input,
.input-select__validation-input:focus-visible {
  border: none;
  outline: 0;
  color: transparent;
}

.input-select__select-button:focus-within,
.input-select__select-button--expanded {
  border-color: var(--color-primary-500);
}

.input-select__select-button.input-select__select-button--error,
.input-select__select-button.input-select__select-button--error:focus-within {
  border-color: var(--color-error-500);
}

.input-select__select-button.input-select__select-button--success,
.input-select__select-button.input-select__select-button--success:focus-within {
  border-color: var(--color-success-500);
}

.input-select__value {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  flex-shrink: 1;
  color: var(--color-grey-900);
  font: var(--font-base-medium);
}

.input-select__chevron {
  margin: 0 0 0 auto;
  transform: rotate(0deg);
  transition: transform var(--transition-fast);
  flex-shrink: 0;
}

.input-select__chevron--menu-hidden {
  transform: rotate(180deg);
}
