.evaluation-container {
  --column-width: calc(var(--dialog-stretchable-max-width) / 2 - 2 * var(--spacing-8));

  display: grid;
  grid-auto-flow: row;
  gap: var(--spacing-4) var(--spacing-10);
  align-items: start;
  grid-auto-columns: minmax(var(--column-width), 1fr);
  grid-template-columns: minmax(auto, calc(var(--dialog-max-width) - 2 * var(--spacing-8)));
}

.evaluation-container__spinner {
  font-size: 2em;
  margin: 0 auto;
  padding: var(--spacing-1);
  overflow: hidden;
}
