.conflicts {
  /* noop */
}

.conflicts__item {
  color: var(--color-grey-800);
  font: var(--font-base-medium);
  margin: 0;
}

.conflicts__item:not(:first-child) {
  margin-top: var(--spacing-8);
}

.conflicts__item-title {
  font: var(--font-base-medium);
  margin: 0 0 var(--spacing-5);
}

.conflicts__item-text {
  font: var(--font-sm-medium);
  margin: var(--spacing-5) 0 0;
}
