@font-face {
  font-family: "Inter";
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  src: url(./fonts/inter/Inter-Regular.woff2) format("woff2"), url(./fonts/inter/Inter-Regular.woff) format("woff");
}

@font-face {
  font-family: "Inter";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url(./fonts/inter/Inter-Medium.woff2) format("woff2"), url(./fonts/inter/Inter-Medium.woff) format("woff");
}

@font-face {
  font-family: "Inter";
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  src: url(./fonts/inter/Inter-Bold.woff2) format("woff2"), url(./fonts/inter/Inter-Bold.woff) format("woff");
}
