.calendar-dialog-absence-group__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: var(--spacing-5);
  flex-direction: column;
}

.calendar-dialog-absence-group__employment-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.calendar-dialog-absence-group__employment-avatar {
  flex-shrink: 0;
}
