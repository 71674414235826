.snackbar {
  bottom: var(--spacing-2);
  left: 0;
  margin: 0 var(--spacing-3);
  max-width: 690px;
  overflow: hidden;
  position: fixed;
  box-shadow: var(--shadow-lg);
  transition: transform var(--transition-default), opacity var(--transition-default);
  width: calc(100% - 2 * var(--spacing-3));
  z-index: 99999;
}

.transition-slide-enter.snackbar,
.transition-slide-leave-to.snackbar {
  opacity: 0;
  transform: translateY(50vh);
}

.transition-slide-enter-to.snackbar,
.transition-slide-leave.snackbar {
  opacity: 1;
  transform: translateY(0);
}
