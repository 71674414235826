.badge {
  --badge-color: var(--color-grey-100);
  --text-color: var(--color-grey-700);
  --close-background: var(--color-grey-200);
  --close-active-color: var(--badge-color);

  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: var(--spacing-1);
  padding: var(--spacing-05) var(--spacing-2);
  box-sizing: border-box;
  background: var(--badge-color);
}

.badge--preset-primary {
  --badge-color: var(--color-primary-100);
  --text-color: var(--color-primary-700);
  --close-background: var(--color-primary-200);
}

.badge--preset-error {
  --badge-color: var(--color-error-100);
  --text-color: var(--color-error-700);
  --close-background: var(--color-error-200);
}

.badge--preset-info {
  --badge-color: var(--color-info-100);
  --text-color: var(--color-info-600);
  --close-background: var(--color-info-200);
}

.badge--preset-success {
  --badge-color: var(--color-success-100);
  --text-color: var(--color-success-700);
  --close-background: var(--color-success-200);
}

.badge--preset-warning {
  --badge-color: var(--color-warning-50);
  --text-color: var(--color-warning-600);
  --close-background: var(--color-warning-200);
}

.badge--size-extra-small {
  --dot-size: 0.25rem;
  --icon-size: 0.75rem;
  --close-size: 0.75rem;

  font: var(--font-xs-medium);
}

.badge--size-small {
  --dot-size: 0.375rem;
  --icon-size: 1rem;
  --close-size: 1rem;

  font: var(--font-sm-medium);
}

.badge--size-medium {
  --dot-size: 0.5rem;
  --icon-size: 1.25rem;
  --close-size: 1.15rem;

  font: var(--font-base-medium);
}

.badge__label {
  color: var(--text-color);
}

.badge__icon {
  margin-right: 0.125rem;
  color: var(--text-color);
}

.badge__dot {
  width: var(--dot-size);
  height: var(--dot-size);
  border-radius: calc(var(--dot-size) / 2);
  background-color: var(--text-color);
  margin-right: var(--spacing-2);
}

.badge__close {
  margin-left: var(--spacing-05);
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--close-size);
  height: var(--close-size);
  background: none;
  border: none;
  border-radius: 0.125rem;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: var(--text-color);
}

.badge__close:hover {
  background: var(--close-background);
}

.badge__close:active,
.badge__close:focus {
  background: var(--text-color);
  color: var(--close-active-color);
}
