.form-section {
  display: grid;
  grid-template-rows: auto auto;
  width: 100%;
  border-radius: var(--spacing-1);
  border: 1px solid var(--color-grey-200);
}

.form-section:empty {
  border: 0;
}

.form-section__content {
  display: grid;
  gap: var(--spacing-4) var(--spacing-6);
  grid-template-columns: repeat(2, 1fr);
  padding: var(--spacing-2) var(--spacing-4);
  grid-column: 1 / -1;
}

.form-section__heading {
  grid-column: 1 / -1;
  margin-bottom: var(--spacing-2);
}
