.input-checkbox {
  --shadow-focus: 0 0 0 1px var(--color-bw-50), 0 0 0 2px var(--color-primary-500);
  --icon-size: var(--spacing-4);
  --size: var(--spacing-5);

  display: inline-flex;
  flex-flow: column nowrap;
  position: relative;
  height: fit-content;
  max-width: 100%;
}

.input-checkbox__input-wrapper {
  align-items: stretch;
  display: flex;
  flex: 1;
}

.input-checkbox__input {
  height: 0;
  width: 0;
  margin-right: calc(var(--icon-size) + var(--spacing-2));
  appearance: none;
}

.input-checkbox__label {
  color: var(--color-grey-900);
  cursor: pointer;
  display: flex;
  font: var(--font-sm-medium);
  justify-content: inherit;
  align-items: flex-start;
  position: relative;
  min-height: var(--spacing-4);
  word-break: break-word;
  flex: 1;
}

.input-checkbox__icon {
  margin-right: var(--spacing-2);
}

.input-checkbox__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.input-checkbox__description {
  color: var(--color-grey-500);
  font-weight: var(--font-weight-regular);
}

.input-checkbox__input-checkmark {
  position: absolute;
  left: 0;
  top: 2px;
  height: var(--icon-size);
  width: var(--icon-size);
  border: 1px solid var(--color-grey-300);
  border-radius: 2px;
  box-sizing: border-box;
  cursor: pointer;
  transition: border var(--transition-fast);
}

.input-checkbox__input-checkmark::after {
  cursor: pointer;
  content: " ";
  width: 10px;
  height: 5px;
  background: none;
  border-left: 2px solid var(--color-bw-50);
  border-bottom: 2px solid var(--color-bw-50);
  transform: scale(0) rotate(-45deg);
  position: absolute;
  left: 3px;
  top: var(--spacing-1);
  transition: border var(--transition-fast), background-color var(--transition-fast);
}

.input-checkbox--disabled .input-checkbox__input-checkmark {
  background-color: var(--color-grey-200);
  transition: background-color var(--transition-fast);
}

.input-checkbox--disabled .input-checkbox__label,
.input-checkbox--disabled .input-checkbox__input-checkmark {
  cursor: default;
}

.input-checkbox__input:disabled ~ .input-checkbox__input-checkmark::after {
  cursor: default;
}

.input-checkbox__input:hover ~ .input-checkbox__input-checkmark {
  background-color: var(--color-grey-100);
  transition: background-color var(--transition-fast);
}

.input-checkbox__input:disabled ~ .input-checkbox__input-checkmark {
  background-color: var(--color-grey-200);
  border-color: var(--color-grey-300);
  transition: background-color var(--transition-fast), border-color var(--transition-fast);
}

.input-checkbox__input:indeterminate ~ .input-checkbox__input-checkmark {
  background-color: var(--color-primary-400);
  transition: background-color var(--transition-fast);
}

.input-checkbox__input:indeterminate ~ .input-checkbox__input-checkmark::after {
  transform: scale(1) rotate(0deg);
  border-left: 0 solid var(--color-bw-50);
  border-bottom: 2px solid var(--color-bw-50);
  opacity: 1;
  width: var(--spacing-2);
  left: var(--spacing-1);
  top: var(--spacing-1);
}

.input-checkbox__input:focus ~ .input-checkbox__input-checkmark {
  box-shadow: var(--shadow-focus);
}

.input-checkbox__input:checked ~ .input-checkbox__input-checkmark {
  background-color: var(--color-primary-400);
  transition: background-color var(--transition-fast);
  border-width: 0;
}

.input-checkbox__input:indeterminate:hover ~ .input-checkbox__input-checkmark {
  background-color: var(--color-primary-500);
  transition: background-color var(--transition-fast);
}

.input-checkbox__input:checked ~ .input-checkbox__input-checkmark::after {
  transform: scale(1) rotate(-45deg);
}

.input-checkbox--disabled .input-checkbox__input:indeterminate ~ .input-checkbox__input-checkmark,
.input-checkbox--disabled .input-checkbox__input:checked ~ .input-checkbox__input-checkmark {
  background-color: var(--color-primary-500);
  transition: background-color var(--transition-fast);
  opacity: 0.4;
}

.input-checkbox__input:checked:hover ~ .input-checkbox__input-checkmark {
  background-color: var(--color-primary-500);
  border-color: transparent;
  transition: background-color var(--transition-fast), border-color var(--transition-fast);
}

.input-checkbox--invalid .input-checkbox__label,
.input-checkbox--disabled .input-checkbox__label {
  color: var(--color-grey-500);
  transition: color var(--transition-fast);
}

.input-checkbox--invalid .input-checkbox__input:not(:checked) + .input-checkbox__label ~ .input-checkbox__input-checkmark {
  border-color: var(--color-grey-500);
  transition: border-color var(--transition-fast);
}
