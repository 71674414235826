:root {
  --font-weight-semibold: 600;
  --font-weight-normal: 500;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-wrap {
  /* this is the only option that is recognized
  * by html2canvas break-word
  * doesn't seem to work
  */
  word-break: break-all;
  white-space: normal;
  overflow-wrap: break-word;
}
