.button-icon {
  fill: currentColor;
}

.button-icon--alone {
  margin: 0 calc(var(--button-padding-vertical) - var(--button-padding-horizontal));
}

.button-icon--left {
  margin-right: var(--spacing-2);
}

.button-icon--right {
  margin-left: var(--spacing-2);
}
