.form-dialog {
  display: grid;
  grid-auto-flow: row;
  grid-gap: var(--spacing-4) 0;
}

.form-dialog__section {
  /* noop */
}

.form-dialog__section--two-column {
  align-items: flex-start;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0 var(--spacing-4);
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
}

.form-dialog__section-label {
  align-items: center;
  color: var(--color-grey-900);
  display: flex;
  font: var(--font-sm-medium);
  height: var(--spacing-6);
}
