:root {
  /* other */
  --dialog-min-height: 888px;
  --dialog-max-width: 768px;
  --dialog-max-width-sm: 480px;

  /* absences */
  --absence-new-color: var(--color-warning-600);
  --absence-new-bg-color: var(--color-warning-100);
  --absence-accepted-color: var(--color-success-600);
  --absence-accepted-bg-color: var(--color-success-100);

  /* availabilities */
  --color-availability-available: var(--color-success-400);
  --color-availability-unavailable: var(--color-success-500);
  --color-availability-available-border: var(--color-error-400);
  --color-availability-unavailable-border: var(--color-error-500);

  /* special days */
  --background-color-special-day: var(--color-warning-400);
  --text-color-special-day: var(--color-warning-900);

  /* employments */
  --size-employee-avatar: 1.6rem;

  /* shift presets */
  --shift-preset-free-color: var(--color-grey-300);

  /* drag-and-drop */
  --color-drop-active: rgba(172, 135, 248, 0.2);
  --color-drag-over-active: rgba(140, 89, 245, 0.5);

  /* date/month picker colors */
  --color-datepicker-item-hovered: var(--color-primary-200);
  --color-datepicker-item-selected: var(--color-primary-100);
  --color-datepicker-selection-boundary: var(--color-primary-300);
}

/* END New color mappings */
html {
  box-sizing: border-box;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  /* generic font settings */
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-regular);
  font-size: 100%;
}

body {
  height: 100%;

  /* TODO: copied from sppt, should not really use them in the future */
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: 'pnum', 'kern' false;
  -moz-font-feature-settings: 'pnum', 'kern' false;
  -ms-font-feature-settings: 'pnum', 'kern' false;
  font-feature-settings: 'pnum', 'kern' false;

  /* prevent overflow-scrolling on safari/iOS */
  overflow: hidden;
}

:global(.vue-recycle-scroller__item-wrapper),
:global(.vue-recycle-scroller__item-view),
:global(.vue-recycle-scroller__slot) {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: inherit;
  grid-gap: inherit;
  gap: inherit;
  -ms-grid-column-span: inherit;
  grid-auto-rows: inherit;
  align-items: flex-start;
}

:global(.vue-recycle-scroller__item-wrapper) {
  overflow: initial !important;
}
