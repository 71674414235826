.section-pay {
  /* noop */
}

.section-pay__section {
  grid-column: 1 / -1;
}

.section-pay__section:not(:last-child) {
  margin-bottom: var(--spacing-8);
}

.section-pay__add-button {
  justify-self: flex-start;
}
