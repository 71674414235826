.dashboard-redirect {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: center;
  padding: var(--spacing-2) var(--spacing-5);
}

.dashboard-redirect__spinner {
  color: var(--shyftplan-green);
  font-size: 2rem;
}
