.spacing-medium {
  --spacing: var(--spacing-8) var(--spacing-8) var(--spacing-6) var(--spacing-8);
}

.spacing-small {
  --spacing: var(--spacing-6);
}

.header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  padding: var(--spacing);
}

.title {
  color: var(--color-grey-900);
  font: var(--font-lg-medium);
  margin: 0;
  user-select: none;
  max-width: calc(100% - 32px); /* 32px => Width of icon button*/
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}
