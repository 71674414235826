:root {
  /* Grey */
  --color-grey-50: #f9fafb;
  --color-grey-100: #f3f4f6;
  --color-grey-200: #e5e7eb;
  --color-grey-300: #d1d5db;
  --color-grey-400: #9ca3af;
  --color-grey-500: #6b7280;
  --color-grey-600: #4b5563;
  --color-grey-700: #374151;
  --color-grey-800: #1f2937;
  --color-grey-900: #111827;

  /* Primary */
  --color-primary-50: #f8f5ff;
  --color-primary-100: #eee7fe;
  --color-primary-200: #ddcffc;
  --color-primary-300: #cdb7fb;
  --color-primary-400: #ac87f8;
  --color-primary-500: #8c59f5;
  --color-primary-600: #6927f2;
  --color-primary-700: #500dd8;
  --color-primary-800: #3e0aa8;
  --color-primary-900: #2c0778;

  /* Info */
  --color-info-50: #ecf3fe;
  --color-info-100: #d3e5fd;
  --color-info-200: #a3c7fa;
  --color-info-300: #73aaf8;
  --color-info-400: #428df5;
  --color-info-500: #106ef2;
  --color-info-600: #0b59c6;
  --color-info-700: #084396;
  --color-info-800: #052d66;
  --color-info-900: #04224e;

  /* Success */
  --color-success-50: #f7fdfb;
  --color-success-100: #e6faf2;
  --color-success-200: #bcf1db;
  --color-success-300: #91e8c4;
  --color-success-400: #67e0ae;
  --color-success-500: #3cd797;
  --color-success-600: #26ba7d;
  --color-success-700: #1d9061;
  --color-success-800: #156644;
  --color-success-900: #0c3b27;

  /* Warning */
  --color-warning-50: #fff5e5;
  --color-warning-100: #ffebcc;
  --color-warning-200: #ffd799;
  --color-warning-300: #ffc266;
  --color-warning-400: #ffae33;
  --color-warning-500: #fd9900;
  --color-warning-600: #e58b00;
  --color-warning-700: #b26c00;
  --color-warning-800: #804d00;
  --color-warning-900: #663e00;

  /* Error */
  --color-error-50: #fff0f3;
  --color-error-100: #ffd6e0;
  --color-error-200: #ffbdcc;
  --color-error-300: #ffa3b8;
  --color-error-400: #ff7091;
  --color-error-500: #ff3d6a;
  --color-error-600: #ff0a43;
  --color-error-700: #d60032;
  --color-error-800: #a30026;
  --color-error-900: #70001a;

  /* B&W */
  --color-bw-50: #fff;
  --color-bw-100: #000;
}
