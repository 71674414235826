.chat {
  background: var(--color-bw-50);
  color: var(---color-grey-500);
  flex: 0;
  font-size: var(--font-size-sm);
  overflow: hidden;
  transition: flex var(--transition-duration-default);
}

.chat--open {
  flex: 2;
  overflow: hidden;
}

/* legacy */
:global(.chat-container) {
  height: 100% !important;
}

:global(.icon-button) {
  color: var(--shyftplan-green);
  text-decoration: none;
}

:global(.sp-absence)::before {
  content: "\e600";
}

:global(.sp-absences-state-approved)::before {
  content: "\e925";
}

:global(.sp-add-circle)::before {
  content: "\e601";
}

:global(.sp-alarm-on)::before {
  content: "\e911";
}

:global(.sp-alarm-off)::before {
  content: "\e912";
}

:global(.sp-application)::before {
  content: "\e93b";
}

:global(.sp-arrow-back)::before {
  content: "\e602";
}

:global(.sp-arrow-next)::before {
  content: "\e603";
}

:global(.sp-bell)::before {
  content: "\e604";
}

:global(.sp-billing)::before {
  content: "\e605";
}

:global(.sp-block)::before {
  content: "\e913";
}

:global(.sp-blocked)::before {
  content: "\e606";
}

:global(.sp-cancel)::before {
  content: "\e607";
}

:global(.sp-cash-usd)::before {
  content: "\e91e";
}

:global(.sp-chat)::before {
  content: "\e934";
}

:global(.sp-change)::before {
  content: "\e93c";
}

:global(.sp-check-circle)::before {
  content: "\e608";
}

:global(.sp-chevron-down)::before {
  content: "\e90b";
}

:global(.sp-chevron-up)::before {
  content: "\e90a";
}

:global(.sp-clear)::before {
  content: "\e609";
}

:global(.sp-clock)::before {
  content: "\e91f";
}

:global(.sp-comment)::before {
  content: "\e91a";
}

:global(.sp-copy)::before {
  content: "\e916";
}

:global(.sp-dashboard)::before {
  content: "\e60a";
}

:global(.sp-delete)::before {
  content: "\e917";
}

:global(.sp-done)::before {
  content: "\e60b";
}

:global(.sp-day)::before {
  content: "\e630";
}

:global(.sp-direct-swap)::before {
  content: "\e939";
}

:global(.sp-dots-horizontal)::before {
  content: "\e91b";
}

:global(.sp-download)::before {
  content: "\e92c";
}

:global(.sp-earned-amount)::before {
  content: "\e91c";
}

:global(.sp-edit)::before {
  content: "\e60c";
}

:global(.sp-edit-table)::before {
  content: "\e92a";
}

:global(.sp-equal)::before {
  content: "\e62a";
}

:global(.sp-evaluation)::before {
  content: "\e60d";
}

:global(.sp-event)::before {
  content: "\e60e";
}

:global(.sp-export)::before {
  content: "\e931";
}

:global(.sp-export-table)::before {
  content: "\e929";
}

:global(.sp-eye-on)::before {
  content: "\e635";
}

:global(.sp-eye-off)::before {
  content: "\e636";
}

:global(.sp-facebook)::before {
  content: "\e901";
}

:global(.sp-filter)::before {
  content: "\e90d";
}

:global(.sp-flag)::before {
  content: "\e626";
}

:global(.sp-floppy-disk)::before {
  content: "\e908";
}

:global(.sp-generate)::before {
  content: "\e918";
}

:global(.sp-google-drive)::before {
  content: "\e935";
}

:global(.sp-google-plus)::before {
  content: "\e903";
}

:global(.sp-help)::before {
  content: "\e60f";
}

:global(.sp-import)::before {
  content: "\e937";
}

:global(.sp-info)::before {
  content: "\e610";
}

:global(.sp-instagram)::before {
  content: "\e906";
}

:global(.sp-insurance)::before {
  content: "\e62d";
}

:global(.sp-link)::before {
  content: "\e922";
}

:global(.sp-linkedin)::before {
  content: "\e905";
}

:global(.sp-link-off)::before {
  content: "\e921";
}

:global(.sp-location)::before {
  content: "\e611";
}

:global(.sp-login)::before {
  content: "\e907";
}

:global(.sp-mail)::before {
  content: "\e612";
}

:global(.sp-maximized)::before {
  content: "\e633";
}

:global(.sp-maximum-money)::before {
  content: "\e91d";
}

:global(.sp-message)::before {
  content: "\e612";
}

:global(.sp-minimized)::before {
  content: "\e634";
}

:global(.sp-minus)::before {
  content: "\e623";
}

:global(.sp-mobile)::before {
  content: "\e62e";
}

:global(.sp-month)::before {
  content: "\e632";
}

:global(.sp-month-position)::before {
  content: "\e92e";
}

:global(.sp-month-employees)::before {
  content: "\e92f";
}

:global(.sp-moon)::before {
  content: "\e936";
}

:global(.sp-note)::before {
  content: "\e930";
}

:global(.sp-occupied)::before {
  content: "\e637";
}

:global(.sp-overassigned-shifts)::before {
  content: "\e923";
}

:global(.sp-paperclip)::before {
  content: "\e938";
}

:global(.sp-payment)::before {
  content: "\e629";
}

:global(.sp-pay-fixed)::before {
  content: "\e61f";
}

:global(.sp-pay-bonus_fixed)::before {
  content: "\e61f";
}

:global(.sp-pay-bonus_percentage)::before {
  content: "\e61f";
}

:global(.sp-pay-hourly)::before {
  content: "\e622";
}

:global(.sp-pay-monthly)::before {
  content: "\e61d";
}

:global(.sp-person)::before {
  content: "\e613";
}

:global(.sp-personal)::before {
  content: "\e614";
}

:global(.sp-pause)::before {
  content: "\e90c";
}

:global(.sp-phone-old)::before {
  content: "\e909";
}

:global(.sp-pin)::before {
  content: "\e627";
}

:global(.sp-play)::before {
  content: "\e915";
}

:global(.sp-plus)::before {
  content: "\e624";
}

:global(.sp-position)::before {
  content: "\e615";
}

:global(.sp-print)::before {
  content: "\e616";
}

:global(.sp-punch)::before {
  content: "\e617";
}

:global(.sp-punch_timing)::before {
  content: "\e617";
}

:global(.sp-range)::before {
  content: "\e92b";
}

:global(.sp-remove-circle)::before {
  content: "\e618";
}

:global(.sp-remaining-money)::before {
  content: "\e920";
}

:global(.sp-report-problem)::before {
  content: "\e924";
}

:global(.sp-request)::before {
  content: "\e93d";
}

:global(.sp-reset)::before {
  content: "\e919";
}

:global(.sp-restore)::before {
  content: "\e911";
}

:global(.sp-search)::before {
  content: "\e619";
}

:global(.sp-send-message)::before {
  content: "\e933";
}

:global(.sp-settings)::before {
  content: "\e61a";
}

:global(.sp-shiftplan)::before {
  content: "\e61d";
}

:global(.sp-shift-full)::before {
  content: "\e910";
}

:global(.sp-shift-horizontal)::before {
  content: "\e61b";
}

:global(.sp-shift-open)::before {
  content: "\e90f";
}

:global(.sp-shift-vertical)::before {
  content: "\e61c";
}

:global(.sp-sidebar-collapse)::before {
  content: "\e928";
}

:global(.sp-sort)::before {
  content: "\e90e";
}

:global(.sp-star)::before {
  content: "\e61f";
}

:global(.sp-star-circle)::before {
  content: "\e61e";
}

:global(.sp-survey)::before {
  content: "\e62f";
}

:global(.sp-swap)::before {
  content: "\e93a";
}

:global(.sp-swap-circle)::before {
  content: "\e620";
}

:global(.sp-tag)::before {
  content: "\e932";
}

:global(.sp-time)::before {
  content: "\e622";
}

:global(.sp-twitter)::before {
  content: "\e902";
}

:global(.sp-unoccupied)::before {
  content: "\e900";
}

:global(.sp-upload)::before {
  content: "\e62c";
}

:global(.sp-user-circle)::before {
  content: "\e625";
}

:global(.sp-week)::before {
  content: "\e631";
}

:global(.sp-week-employees)::before {
  content: "\e92d";
}

:global(.sp-work)::before {
  content: "\e62b";
}

:global(.sp-xing)::before {
  content: "\e904";
}
