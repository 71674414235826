.input-combobox {
  position: relative;
}

.input-combobox__input-wrappper,
.input-combobox__input {
  width: 100%;
}

.input-combobox__hint-text {
  display: block;
  font: var(--font-sm-regular);
  margin-top: var(--spacing-2);
  color: var(--color-grey-500);
}

.input-combobox__hint-text.input-combobox__hint-text-success,
.input-combobox__suffix-icon--success {
  color: var(--color-success-500);
}

.input-combobox__hint-text.input-combobox__hint-text--error,
.input-combobox__suffix-icon--error {
  color: var(--color-error-500);
}

.input-combobox__error {
  color: var(--color-error-500);
  font: var(--font-sm-medium);
}
