.card-with-link {
  display: flex;
  align-items: center;
  gap: var(--spacing-3);
  transition: background var(--transition-default);
  border: 1px solid var(--border-color, transparent);
  outline: 1px solid var(--outline-color, transparent);
}

.card-with-link:hover {
  background: var(--color-grey-100);
}

.card-with-link:focus,
.card-with-link:active {
  --border-color: var(--color-primary-500);
  --outline-color: var(--color-primary-200);
}

.card-with-link__icon {
  flex-shrink: 0;
  color: var(--color-grey-500);
}

.card-with-link__content {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  font: var(--font-sm-medium);
  color: var(--color-grey-700);
}

.card-with-link__link {
  text-decoration: none;
}
