.input-toggle {
  --shadow-focus: 0 0 0 1px var(--color-bw-50), 0 0 0 2px var(--color-primary-500);
  --color-disabled: rgba(140, 89, 245, 0.4);

  /* 19px */
  --knob-size: var(--spacing-5);

  /* 24px */
  --track-height: var(--spacing-6);

  /* 44px */
  --track-width: 2.75rem;

  display: inline-flex;
  justify-content: space-between;
  margin-right: 2px;
}

.input-toggle--has-label {
  display: flex;
}

.input-toggle__input {
  height: 0;
  width: 0;
  opacity: 0;
}

.input-toggle__switch {
  background: var(--color-grey-300);
  content: "";
  border-radius: calc(var(--track-height) / 2);
  cursor: pointer;
  display: inline-block;
  height: var(--track-height);
  position: relative;
  transition: background-color var(--transition-fast), color var(--transition-fast);
  min-width: var(--track-width);
  color: var(--color-grey-500);
  align-self: center;
}

.input-toggle__icon {
  position: absolute;
  width: 12px;
  height: 24px;
  top: 0;
  z-index: 10;
}

.input-toggle__icon-unchecked {
  left: 6px;
  right: auto;
}

.input-toggle__icon-checked {
  display: none;
}

.input-toggle__switch::after {
  background: var(--color-bw-50);
  border-radius: calc(var(--track-height) / 2);
  content: "";
  display: block;
  height: var(--knob-size);
  left: 0;
  margin: calc((var(--track-height) - var(--knob-size)) / 2);
  position: absolute;
  transition: transform var(--transition-fast), background-color var(--transition-fast), color var(--transition-fast);
  top: 0;
  width: var(--knob-size);
}

.input-toggle__label {
  color: var(--color-grey-700);
  cursor: pointer;
  display: flex;
  font: var(--font-sm-medium);
  width: calc(100% - var(--track-width));
  justify-content: inherit;
  transition: color var(--transition-fast);
}

.input-toggle--has-label .input-toggle__label {
  flex-direction: column;
}

.input-toggle__description {
  color: var(--color-grey-500);
  font-weight: var(--font-weight-regular);
  transition: color var(--transition-fast);
}

.input-toggle--disabled .input-toggle__label {
  cursor: default;
}

.input-toggle__switch:hover {
  background: var(--color-grey-400);
}

.input-toggle__input:focus + .input-toggle__switch {
  box-shadow: var(--shadow-focus);
}

.input-toggle__input:disabled + .input-toggle__switch {
  background: var(--color-grey-200);
  color: var(--color-grey-300);
  transition: background-color var(--transition-fast), color var(--transition-fast);
  cursor: default;
}

.input-toggle__input:checked + .input-toggle__switch,
.input-toggle__input:checked:focus + .input-toggle__switch {
  background: var(--color-primary-400);
  color: var(--color-primary-400);
  transition: background-color var(--transition-fast), color var(--transition-fast);
}

.input-toggle__input:checked:hover + .input-toggle__switch {
  background: var(--color-primary-500);
  color: var(--color-primary-500);
  transition: background-color var(--transition-fast), color var(--transition-fast);
}

.input-toggle__input:checked:disabled + .input-toggle__switch {
  background: var(--color-primary-500);
  color: var(--color-primary-500);
  transition: background-color var(--transition-fast), color var(--transition-fast);
  opacity: 0.4;
}

.input-toggle__input:checked + .input-toggle__switch::after {
  transform: translateX(calc(var(--track-width) - var(--track-height)));
  transition: transform var(--transition-fast);
}

.input-toggle__input:checked + .input-toggle__switch .input-toggle__icon-unchecked {
  display: none;
  transition: display var(--transition-fast);
}

.input-toggle__input:checked + .input-toggle__switch .input-toggle__icon-checked {
  display: block;
  transition: display var(--transition-fast);
  left: auto;
  right: 6px;
}
