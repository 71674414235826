.dropdown {
  position: relative;
}

.dropdown__button {
  align-items: center;
  background: 0;
  border: 0;
  color: currentcolor;
  cursor: pointer;
  display: flex;
  outline: 0;
  padding: 0;
}

.dropdown__button::after {
  border: 4px solid;
  border-color: currentcolor transparent transparent;
  content: "";
  height: 0;
  margin: 0 0 0 8px;
  position: relative;
  top: 2px;
  width: 0;
}

.dropdown__menu {
  background: var(--color-bw-50);
  border-radius: 4px;
  border: 1px solid rgb(0 0 0 / 15%);
  box-shadow: 0 6px 12px rgb(0 0 0 / 17.5%);
  font-size: 14px;
  list-style: none;
  margin: 0;

  /* this is just a simple heuristic, we won't be using the component in the future anyway */
  max-height: 50vh;
  min-width: 160px;
  overflow-y: auto;
  padding: 5px 0;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1000;
}

.dropdown__menu--hidden {
  display: none;
}

.dropdown__menu-item {
  color: #656565;
  display: block;
  text-decoration: none;
  padding: 5px 20px;
  outline: 0;
  white-space: nowrap;
}

.dropdown__menu-item:focus,
.dropdown__menu-item:hover {
  background: #f5f5f5;
  color: #333;
}

.dropdown__menu-item-separator {
  background: rgb(0 0 0 / 15%);
  height: 1px;
  margin: var(--spacing-3) 0;
}
