.loader {
  display: inline-block;
  margin: 3.5em auto 2.5em;
  position: relative;
  text-align: center;
  width: 100%;
}

.loader__svg {
  height: 64px;
  position: relative;
  width: 100%;
  z-index: 10;
}
