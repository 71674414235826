.section-general__row {
  /* noop */
}

.section-general__row--full-width {
  grid-column: span 2;
  width: 100%;
}

.section-general__row--side-by-side {
  width: 100%;
  grid-column: span 2;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0 var(--spacing-6);
  grid-template-columns: 1fr 1fr;
}
