/* stylelint-disable selector-class-pattern */

@font-face {
  font-family: sp-icon-font;
  src: url("./sp-icon-font.eot");
  src:
    url("./sp-icon-font.eot?#iefix") format("embedded-opentype"),
    url("./sp-icon-font.woff") format("woff"),
    url("./sp-icon-font.ttf") format("truetype"),
    url("./sp-icon-font.svg#sp-font") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="sp-"],
[class*=" sp-"] {
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: sp-icon-font;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="sp-"]::before,
[class*=" sp-"]::before {
  display: block;
  content: "";
}

.sp-absence::before {
  content: "\e600";
}

.sp-absences-state-approved::before {
  content: "\e925";
}

.sp-add-circle::before {
  content: "\e601";
}

.sp-alarm-on::before {
  content: "\e911";
}

.sp-alarm-off::before {
  content: "\e912";
}

.sp-application::before {
  content: "\e93b";
}

.sp-arrow-back::before {
  content: "\e602";
}

.sp-arrow-next::before {
  content: "\e603";
}

.sp-bell::before {
  content: "\e604";
}

.sp-billing::before {
  content: "\e605";
}

.sp-block::before {
  content: "\e913";
}

.sp-blocked::before {
  content: "\e606";
}

.sp-calendar-open-shifts::before {
  content: "\e93e";
}

.sp-cancel::before {
  content: "\e607";
}

.sp-cash-usd::before {
  content: "\e91e";
}

.sp-chat::before {
  content: "\e934";
}

.sp-change::before {
  content: "\e93c";
}

.sp-check-circle::before {
  content: "\e608";
}

.sp-chevron-down::before {
  content: "\e90b";
}

.sp-chevron-up::before {
  content: "\e90a";
}

.sp-clear::before {
  content: "\e609";
}

.sp-clock::before {
  content: "\e91f";
}

.sp-comment::before {
  content: "\e91a";
}

.sp-copy::before {
  content: "\e916";
}

.sp-dashboard::before {
  content: "\e60a";
}

.sp-delete::before {
  content: "\e917";
}

.sp-done::before {
  content: "\e60b";
}

.sp-day::before {
  content: "\e630";
}

.sp-direct-swap::before {
  content: "\e939";
}

.sp-dots-horizontal::before {
  content: "\e91b";
}

.sp-download::before {
  content: "\e92c";
}

.sp-earned-amount::before {
  content: "\e91c";
}

.sp-edit::before {
  content: "\e60c";
}

.sp-edit-table::before {
  content: "\e92a";
}

.sp-edit-employee-rotation::before {
  content: "\e910";
}

.sp-equal::before {
  content: "\e62a";
}

.sp-evaluation::before {
  content: "\e60d";
}

.sp-event::before {
  content: "\e60e";
}

.sp-exclamation::before {
  content: "\e942";
}

.sp-export::before {
  content: "\e931";
}

.sp-export-table::before {
  content: "\e929";
}

.sp-eye-on::before {
  content: "\e635";
}

.sp-eye-off::before {
  content: "\e636";
}

.sp-facebook::before {
  content: "\e901";
}

.sp-filter::before {
  content: "\e90d";
}

.sp-flag::before {
  content: "\e626";
}

.sp-floppy-disk::before {
  content: "\e908";
}

.sp-generate::before {
  content: "\e918";
}

.sp-google-drive::before {
  content: "\e935";
}

.sp-google-plus::before {
  content: "\e903";
}

.sp-help::before {
  content: "\e60f";
}

.sp-import::before {
  content: "\e937";
}

.sp-info::before {
  content: "\e610";
}

.sp-instagram::before {
  content: "\e906";
}

.sp-insurance::before {
  content: "\e62d";
}

.sp-link::before {
  content: "\e922";
}

.sp-linkedin::before {
  content: "\e905";
}

.sp-link-off::before {
  content: "\e921";
}

.sp-location::before {
  content: "\e611";
}

.sp-login::before {
  content: "\e907";
}

.sp-mail::before {
  content: "\e612";
}

.sp-maximized::before {
  content: "\e633";
}

.sp-maximum-money::before {
  content: "\e91d";
}

.sp-message::before {
  content: "\e612";
}

.sp-minimized::before {
  content: "\e634";
}

.sp-minus::before {
  content: "\e623";
}

.sp-mobile::before {
  content: "\e62e";
}

.sp-month::before {
  content: "\e632";
}

.sp-month-position::before {
  content: "\e92e";
}

.sp-month-employees::before {
  content: "\e92f";
}

.sp-moon::before {
  content: "\e936";
}

.sp-no-conficts-shifts::before {
  content: "\e900";
}

.sp-note::before {
  content: "\e930";
}

.sp-occupied::before {
  content: "\e637";
}

.sp-overassigned-shifts::before {
  content: "\e923";
}

.sp-paperclip::before {
  content: "\e938";
}

.sp-payment::before {
  content: "\e629";
}

.sp-pay-fixed::before {
  content: "\e61f";
}

.sp-pay-bonus_fixed::before {
  content: "\e61f";
}

.sp-pay-bonus_percentage::before {
  content: "\e61f";
}

.sp-pay-hourly::before {
  content: "\e622";
}

.sp-pay-monthly::before {
  content: "\e61d";
}

.sp-person::before {
  content: "\e613";
}

.sp-personal::before {
  content: "\e614";
}

.sp-pause::before {
  content: "\e90c";
}

.sp-phone-old::before {
  content: "\e909";
}

.sp-pin::before {
  content: "\e627";
}

.sp-play::before {
  content: "\e915";
}

.sp-plus::before {
  content: "\e624";
}

.sp-position::before {
  content: "\e615";
}

.sp-print::before {
  content: "\e616";
}

.sp-punch::before {
  content: "\e617";
}

.sp-punch_timing::before {
  content: "\e617";
}

.sp-range::before {
  content: "\e92b";
}

.sp-remove-circle::before {
  content: "\e618";
}

.sp-remaining-money::before {
  content: "\e920";
}

.sp-report-problem::before {
  content: "\e924";
}

.sp-request::before {
  content: "\e93d";
}

.sp-reset::before {
  content: "\e919";
}

.sp-restore::before {
  content: "\e911";
}

.sp-rotation::before {
  content: "\e940";
}

.sp-round-exclamation::before {
  content: "\e941";
}

.sp-search::before {
  content: "\e619";
}

.sp-send-message::before {
  content: "\e933";
}

.sp-settings::before {
  content: "\e61a";
}

.sp-shiftplan::before {
  content: "\e61d";
}

.sp-shift-full::before {
  content: "\e910";
}

.sp-shift-horizontal::before {
  content: "\e61b";
}

.sp-shift-open::before {
  content: "\e90f";
}

.sp-shift-vertical::before {
  content: "\e61c";
}

.sp-sidebar-collapse::before {
  content: "\e928";
}

.sp-sort::before {
  content: "\e90e";
}

.sp-star::before {
  content: "\e61f";
}

.sp-star-circle::before {
  content: "\e61e";
}

.sp-survey::before {
  content: "\e62f";
}

.sp-swap::before {
  content: "\e93a";
}

.sp-swap-circle::before {
  content: "\e620";
}

.sp-tag::before {
  content: "\e932";
}

.sp-time::before {
  content: "\e622";
}

.sp-twitter::before {
  content: "\e902";
}

.sp-unoccupied::before {
  content: "\e900";
}

.sp-upload::before {
  content: "\e62c";
}

.sp-user-circle::before {
  content: "\e625";
}

.sp-week::before {
  content: "\e631";
}

.sp-week-employees::before {
  content: "\e92d";
}

.sp-work::before {
  content: "\e62b";
}

.sp-xing::before {
  content: "\e904";
}
