.avatar {
  width: var(--width);
  height: var(--height);
  position: relative;
  border-radius: 50%;
  background-color: var(--color-grey-100);
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar--initials {
  background-color: var(--color-grey-500);
  color: var(--color-bw-50);
  font: var(--font);
}

.avatar__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

/* Sizes */

.avatar--x-small {
  --font: var(--font-xs-regular);
  --width: 1.5rem;
  --height: 1.5rem;
}

.avatar--small {
  --font: var(--font-sm-regular);
  --width: 2rem;
  --height: 2rem;
}

.avatar--medium {
  --font: var(--font-base-regular);
  --width: 2.5rem;
  --height: 2.5rem;
}

.avatar--large {
  --font: var(--font-lg-regular);
  --width: 3rem;
  --height: 3rem;
}

.avatar--x-large {
  --font: var(--font-xl-regular);
  --width: 3.5rem;
  --height: 3.5rem;
}

.avatar--2x-large {
  --font: var(--font-2xl-regular);
  --width: 4rem;
  --height: 4rem;
}

.avatar .avatar__icon {
  width: 100%;
  height: 100%;
  fill: var(--color-grey-300);
}
