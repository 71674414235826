.collisions {
  /* noop */
}

.collisions__section:not(:first-child) {
  margin-top: var(--spacing-7);
}

.collisions_title {
  font: var(--font-base-medium);
  margin: 0 0 var(--spacing-5);
}

.collisions_list {
  display: grid;
  grid-auto-flow: row;
  grid-gap: var(--spacing-3) 0;
  list-style: none;
  margin: 0;
  padding: 0;
}

.collisions_list-item {
  font: var(--font-sm-medium);
  min-width: 0px;
  overflow-wrap: break-word;
}
