.dialog-shift-conflicts {
  /* noop */
}

.dialog-shift-conflicts__buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  place-content: space-between;
}

.dialog-shift-conflicts--buttons--primary {
  display: flex;
}
