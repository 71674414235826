.section:not(:first-child) {
  border-top: 1px solid var(--color-grey-200);
}

.section--inherited {
  /* noop */
}

.section__sub {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: var(--spacing-4) 0;

  /* not supported in Safari < 14.1, won't look too bad though */
  gap: var(--spacing-3) var(--spacing-6);
}

.section__sub:not(:first-child) {
  margin-top: var(--spacing-3);
}

.section__bonus {
  border: 1px solid var(--color-grey-200);
  padding: var(--spacing-6);
}

.section__select {
  background: 0;
  flex: 4;
}

.section__input {
  flex: 1;
  min-width: 10ch;
  overflow: hidden;
  position: relative;
}

.section__input-value {
  width: 100%;
}

.section__button {
  margin-top: var(--spacing-3);
  justify-self: flex-start;
}

.section__button-remove {
  align-self: flex-end;
}
