.form {
  flex: 1;
  grid-template-columns: initial;
  display: contents;
}

.form__pill-select {
  margin: 0 var(--spacing-4);
  width: initial;
}

.form__section-input {
  /* noop */
}

.form__section-input--full-width {
  grid-column: 1 / -1;
}

.form__centered-button {
  margin: 0 auto;
  grid-column: 1 / -1;
}

.form__action-button {
  flex: 1;
}

.form__section-input--side-by-side {
  width: 100%;
  grid-column: span 2;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0 var(--spacing-6);
  grid-template-columns: 1fr 1fr;
}

/*
  form layout
  In some cases we want two forms to be shown side by side and align sections horizontally, so both forms are located in one grid
*/

/*
  wrapper class for slots
*/
.form__slot-wrapper {
  display: contents;
}

.form__section-tags {
  grid-row: 1 / 2;
}

.form__section-general {
  grid-row: 2 / 3;
}

.form__section-breaks {
  grid-row: 3 / 4;
}

.form__section-payments {
  grid-row: 4 / 5;
}

.form__section-note {
  grid-row: 5 / 6;
}

.form__section-actions {
  grid-row: 6 / 7;
}
