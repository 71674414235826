.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: var(--button-height);
  stroke: none;
  font: var(--font);
  border-radius: var(--button-border-radius, 4px);
  box-sizing: border-box;
  padding: var(--button-padding-vertical) var(--button-padding-horizontal);
  background-color: var(--background-color);
  color: var(--font-color);
  transition: var(--transition-fast);
  outline: 1px solid var(--outline-color, transparent);
  border: 1px solid var(--border-color, transparent);
  cursor: pointer;
  text-decoration: none;
}

/* link elements do not support disabled attribute, so we need to use a class there */
.button:disabled,
.button--disabled {
  opacity: 50%;
  pointer-events: none;
}

/*Button Primary*/
/*Fill*/
.button--primary.button--fill {
  --background-color: var(--color-primary-500);
  --font-color: var(--color-bw-50);
}
.button--primary.button--fill:hover:not(:disabled) {
  --background-color: var(--color-primary-600);
}
.button--primary.button--fill:focus {
  --background-color: var(--color-primary-500);
  --border-color: var(--color-bw-50);
  --outline-color: var(--color-primary-500);
}

/*Stoke*/
.button--primary.button--stroke {
  --background-color: var(--color-bw-50);
  --font-color: var(--color-primary-500);
  --border-color: var(--color-primary-500);
}
.button--primary.button--stroke:hover:not(:disabled) {
  --font-color: var(--color-primary-600);
  --border-color: var(--color-primary-600);
}
.button--primary.button--stroke:focus {
  --border-color: var(--color-primary-500);
  --outline-color: var(--color-primary-200);
}

/*Ghost*/
.button--primary.button--ghost {
  --background-color: var(--color-primary-100);
  --font-color: var(--color-primary-700);
}
.button--primary.button--ghost:hover:not(:disabled) {
  --background-color: var(--color-primary-200);
  --font-color: var(--color-primary-600);
}

.button--primary.button--ghost:focus {
  --border-color: var(--color-bw-50);
  --font-color: var(--color-primary-700);
  --outline-color: var(--color-primary-700);
}

/*Button Primary*/
/*Fill*/
.button--secondary.button--fill {
  --background-color: var(--color-grey-800);
  --font-color: var(--color-bw-50);
}
.button--secondary.button--fill:hover:not(:disabled) {
  --background-color: var(--color-grey-900);
}
.button--secondary.button--fill:focus {
  --background-color: var(--color-grey-800);
  --border-color: var(--color-bw-50);
  --outline-color: var(--color-grey-800);
}
.button--secondary.button--fill:disabled {
  opacity: 100%;
  color: var(--color-grey-400);
}
/*Stroke*/
.button--secondary.button--stroke {
  --background-color: var(--color-bw-50);
  --font-color: var(--color-grey-700);
  --border-color: var(--color-grey-300);
}
.button--secondary.button--stroke:hover:not(:disabled) {
  --border-color: var(--color-grey-300);
  --background-color: var(--color-grey-50);
}

.button--secondary.button--stroke:focus {
  --border-color: var(--color-grey-300);
  --outline-color: var(--color-grey-200);
}

/*Ghost*/
.button--secondary.button--ghost {
  --background-color: var(--color-grey-100);
  --font-color: var(--color-grey-700);
}
.button--secondary.button--ghost:hover:not(:disabled) {
  --background-color: var(--color-grey-200);
  --font-color: var(--color-grey-600);
}

.button--secondary.button--ghost:focus {
  --border-color: var(--color-bw-50);
  --font-color: var(--color-grey-700);
  --outline-color: var(--color-grey-700);
}


/*Button Success*/
/*Fill*/
.button--success.button--fill {
  --background-color: var(--color-success-600);
  --font-color: var(--color-bw-50);
}
.button--success.button--fill:hover:not(:disabled) {
  --background-color: var(--color-success-700);
}
.button--success.button--fill:focus {
  --background-color: var(--color-success-600);
  --border-color: var(--color-bw-50);
  --outline-color: var(--color-success-600);
}
/*Stroke*/
.button--success.button--stroke {
  --background-color: var(--color-bw-50);
  --font-color: var(--color-success-700);
  --border-color: var(--color-success-300);
}
.button--success.button--stroke:hover:not(:disabled) {
  --border-color: var(--color-success-300);
  --background-color: var(--color-success-50);
}

.button--success.button--stroke:focus {
  --border-color: var(--color-success-300);
  --outline-color: var(--color-success-200);
}
/*Ghost*/
.button--success.button--ghost {
  --background-color: var(--color-success-100);
  --font-color: var(--color-success-700);
}
.button--success.button--ghost:hover:not(:disabled) {
  --background-color: var(--color-success-200);
  --font-color: var(--color-success-600);
}

.button--success.button--ghost:focus {
  --border-color: var(--color-bw-50);
  --font-color: var(--color-success-700);
  --outline-color: var(--color-success-700);
}

/* Button info */
/* Fill */
.button--info.button--fill {
  --background-color: var(--color-info-600);
  --font-color: var(--color-bw-50);
}
.button--info.button--fill:hover:not(:disabled) {
  --background-color: var(--color-info-700);
}
.button--info.button--fill:focus {
  --background-color: var(--color-info-600);
  --border-color: var(--color-bw-50);
  --outline-color: var(--color-info-600);
}
/* Stroke */
.button--info.button--stroke {
  --background-color: var(--color-bw-50);
  --font-color: var(--color-info-700);
  --border-color: var(--color-info-300);
}
.button--info.button--stroke:hover:not(:disabled) {
  --border-color: var(--color-info-300);
  --background-color: var(--color-info-50);
}

.button--info.button--stroke:focus {
  --border-color: var(--color-info-300);
  --outline-color: var(--color-info-200);
}

/*Ghost*/
.button--info.button--ghost {
  --background-color: var(--color-info-100);
  --font-color: var(--color-info-700);
}
.button--info.button--ghost:hover:not(:disabled) {
  --background-color: var(--color-info-200);
  --font-color: var(--color-info-600);
}

.button--info.button--ghost:focus {
  --border-color: var(--color-bw-50);
  --font-color: var(--color-info-700);
  --outline-color: var(--color-info-700);
}

/* Button warning */
/* Fill */
.button--warning.button--fill {
  --background-color: var(--color-warning-600);
  --font-color: var(--color-bw-50);
}
.button--warning.button--fill:hover:not(:disabled) {
  --background-color: var(--color-warning-700);
}
.button--warning.button--fill:focus {
  --background-color: var(--color-warning-600);
  --border-color: var(--color-bw-50);
  --outline-color: var(--color-warning-600);
}
/* Stroke */
.button--warning.button--stroke {
  --background-color: var(--color-bw-50);
  --font-color: var(--color-warning-700);
  --border-color: var(--color-warning-300);
}
.button--warning.button--stroke:hover:not(:disabled) {
  --border-color: var(--color-warning-300);
  --background-color: var(--color-warning-50);
}

.button--warning.button--stroke:focus {
  --border-color: var(--color-warning-300);
  --outline-color: var(--color-warning-200);
}

/*Ghost*/
.button--warning.button--ghost {
  --background-color: var(--color-warning-100);
  --font-color: var(--color-warning-700);
}
.button--warning.button--ghost:hover:not(:disabled) {
  --background-color: var(--color-warning-200);
  --font-color: var(--color-warning-600);
}

.button--warning.button--ghost:focus {
  --border-color: var(--color-bw-50);
  --font-color: var(--color-warning-700);
  --outline-color: var(--color-warning-700);
}

/* Button Error */
/* Fill */
.button--error.button--fill {
  --background-color: var(--color-error-600);
  --font-color: var(--color-bw-50);
}
.button--error.button--fill:hover:not(:disabled) {
  --background-color: var(--color-error-700);
}
.button--error.button--fill:focus {
  --background-color: var(--color-error-600);
  --border-color: var(--color-bw-50);
  --outline-color: var(--color-error-600);
}

/*Stroke*/
.button--error.button--stroke {
  --background-color: var(--color-bw-50);
  --font-color: var(--color-error-700);
  --border-color: var(--color-error-300);
}
.button--error.button--stroke:hover:not(:disabled) {
  --border-color: var(--color-error-300);
  --background-color: var(--color-error-50);
}

.button--error.button--stroke:focus {
  --border-color: var(--color-error-300);
  --outline-color: var(--color-error-200);
}

/* Ghost */
.button--error.button--ghost {
  --background-color: var(--color-error-100);
  --font-color: var(--color-error-700);
}
.button--error.button--ghost:hover:not(:disabled) {
  --background-color: var(--color-error-200);
  --font-color: var(--color-error-600);
}

.button--error.button--ghost:focus {
  --border-color: var(--color-bw-50);
  --font-color: var(--color-error-700);
  --outline-color: var(--color-error-700);
}

/* Button sizes */
.button--x-large {
  --button-height: 56px;
  --button-padding-vertical: 15px;
  --button-padding-horizontal: 19px;
  --font: var(--font-base-medium)
}
.button--large {
  --button-height: 48px;
  --button-padding-vertical: 11px;
  --button-padding-horizontal: 19px;
  --font: var(--font-base-medium)
}

.button--medium {
  --button-height: 40px;
  --button-padding-vertical: 9px;
  --button-padding-horizontal: 15px;
  --font: var(--font-sm-medium)
}

.button--small {
  --button-height: 36px;
  --button-padding-vertical: 7px;
  --button-padding-horizontal: 15px;
  --font: var(--font-sm-medium)
}
.button--x-small {
  --button-height: 32px;
  --button-padding-vertical: 5px;
  --button-padding-horizontal: 11px;
  --font: var(--font-xs-medium)
}
.button--xx-small {
  --button-height: 24px;
  --button-padding-vertical: 3px;
  --button-padding-horizontal: 11px;
  --font: var(--font-xs-medium)
}
