.additional-break {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;

  /* not supported in Safari < 14.1, won't look too bad though */
  gap: var(--spacing-3) var(--spacing-6);
  grid-column: 1 / -1;
}

.additional-break__input {
  flex: 1;
  grid-template-columns: 1fr;
  min-width: 14ch;
  overflow: hidden;
  position: relative;
}

.additional-break__input > div {
  display: flex;
  width: 100%;
}

.additional-break__button-delete {
  align-self: end;
}
